export default class Location {

    constructor() {

        return {
            id_city: 0,
            city_name: '',
            id_country: 0,
            country_name: '',
            lat: 0.0,
            lng: 0.0,
            name: '',
            description: '',
            postal_address: ''
        }
    }
}