<template>
    <section class="invoice-preview-wrapper">
  
      <b-row
        class="invoice-preview" style="margin-top: 0px !important;"
      >
  
        <!-- Col: Left (Invoice Container) -->
        
        <b-col
          cols="12"
          xl="12"
          md="12"
        >
          <b-card
            no-body
            class=""
          >
            <!-- Header -->

            <b-card-body class="pb-0">
              <div class="">
                
                <!-- Header: Left Content -->
                <div>
                  <div class="logo-wrapper">
                    <b-img style="height: 150px !important;"
          src="@/assets/images/logo/logo2.png"
          />            

                    <h4 class="">

                        <!-- Button: Send Invoice -->
  <b-button
    variant="primary"
    class="mb-75"
    @click="createOrder"
    style="width: 100% !important;"
  >
  
    {{computedOrder.timeline.created_at === '' ? 'Crear Cotizacion' : 'Creada: '+computedOrder.timeline.created_at.split('.')[0]+''}}
  </b-button>
   <!-- Button: DOwnload -->
   <b-button
    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
    variant="outline-secondary"
    class="mb-75"
    style="width: 100% !important;"
  >
    Download
  </b-button>
  <!-- Button: Print -->
  <b-button
    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
    variant="outline-secondary"
    class="mb-75"
    @click="printInvoice"
    style="width: 100% !important;"
  >
    Print
  </b-button>

  <!-- Button: Edit -->
  <b-button
    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
    variant="outline-secondary"
    class="mb-75"
    style="width: 100% !important;"
  >
    Edit
  </b-button>

  <!-- Button: Add Payment -->
  <b-button
    v-b-toggle.sidebar-invoice-add-payment
    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
    variant="success"
    class="mb-0"
    style="width: 100% !important;"
  >
    Add Payment
  </b-button>


                    </h4>
                    <div class="row">
                  <div style="padding-left: 20% !important; width: 350px !important;">
                  <p class="card-text mb-25">
                    Lavar Click S.a.S
                  </p>
                  <p class="card-text mb-25">
                    NIT 900511050-1
                  </p>
                  <p class="card-text mb-25">
                    Calle 95 # 13 - 55 Oficina 304<br/>
                    Bogota D.C - Colombia
                  </p>
                  <p class="card-text mb-0">
                    350 4888077
                  </p>
                  </div>

                  </div>
                  <div style="margin-left: -30px !important; padding-bottom: 15px !important; padding-top: 15px !important;">
                    <div>
                    <span class="text-primary">Fecha</span><br/>
                    <span class="invoice-number"> {{ computedOrder.timeline.created_at === '' ? new Date() : new Date(computedOrder.timeline.created_at) }}</span>
                </div>

                  </div>
                  </div>

                    

 

  
                </div>
  
                <!-- Header: Right Content -->
              </div>
            </b-card-body>
  
            <!-- Spacer -->
            <hr style="margin-top: -20px;" />
  
            <!-- Invoice Client & Payment Details -->
            <b-card-body
              class="invoice-padding pt-0"
            >
              <b-row >
  
                <!-- Col: Invoice To -->
                <b-col
                  cols="12"
                  xl="6"
                  class="p-0"
                  style="padding-left: 20px !important;"
                >
                  <h6 class="mb-2">
                    Cliente:
                  </h6>
                  <h6 class="mb-25">
                    {{ computedOrder.customer.full_name }}
                  </h6>
                  <p class="card-text mb-25">
                    DOC {{ computedOrder.customer.gov_id }}
                  </p>
                  <p class="card-text mb-25">
                    {{ computedOrder.customer.location.city_name }}, {{ computedOrder.customer.location.country_name }} {{ computedOrder.customer.location.postal_address }}
                  </p>
                  <p class="card-text mb-25">
                    {{ '315 4888077' }}
                  </p>
                  <p class="card-text mb-0">
                    {{ computedOrder.customer.email }}
                  </p>
                </b-col>
  
                <!-- Col: Payment Details -->
                <b-col
                  xl="6"
                  cols="12"
                  class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
                >
                  <div>
                    <h6 class="mb-2">
                      Detalles de la Cotizacion
                    </h6>
                    <table>
                      <tbody>
                        <tr>
                          <td class="pr-1">
                            Valor Total : 
                          </td>
                          <td>$ <span class="font-weight-bold">{{ paymentDetails.total_price }}</span></td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            Total Servicios :
                          </td>
                          <td>{{ paymentDetails.total_services }}</td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            Total Items :
                          </td>
                          <td>{{ paymentDetails.total_service_items }}</td>
                        </tr>
                        <!-- <tr>
                          <td class="pr-1">
                            IBAN:
                          </td>
                          <td>{{ paymentDetails.iban }}</td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            SWIFT code:
                          </td>
                          <td>{{ paymentDetails.swiftCode }}</td>
                        </tr> -->
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
  
            <!-- Invoice Description: Table -->
            <b-table-lite
              responsive
              :items="computedOrder.logistic.collect.items"
              :fields="['CodigoServicio', 'Item', 'Cantidad', 'PrecioUnitario', 'Precio']"
            >
            <template #cell(CodigoServicio)="data">
                <b-card-text class="font-weight-bold mb-25">
                  {{ data.item.code }}
                </b-card-text>
                <!-- <b-card-text class="text-nowrap">
                  {{ data }}
                </b-card-text> -->
              </template>

              <template #cell(item)="data">
                <b-card-text class="font-weight-bold mb-25">
                  {{ data.item.name }}
                </b-card-text>
                <!-- <b-card-text class="text-nowrap">
                  {{ data }}
                </b-card-text> -->
              </template>
              <template #cell(Cantidad)="data">
                <b-card-text class="font-weight-bold mb-25">
                  {{ data.item.quantity }}
                </b-card-text>
                <!-- <b-card-text class="text-nowrap">
                  {{ data }}
                </b-card-text> -->
              </template>
              <template #cell(PrecioUnitario)="data">
                <b-card-text class="font-weight-bold mb-25">
                  {{ data.item.price }}
                </b-card-text>
                <!-- <b-card-text class="text-nowrap">
                  {{ data }}
                </b-card-text> -->
              </template>
              <template #cell(precio)="data">
                <b-card-text class="font-weight-bold mb-25">
                  {{ data.item.price*data.item.quantity }}
                </b-card-text>
                <!-- <b-card-text class="text-nowrap">
                  {{ data }}
                </b-card-text> -->
              </template>

            </b-table-lite>
  
            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-pb-0" style="margin-top: -20px;">
              <b-row>
  
                <!-- Col: Sales Persion -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-0 mt-3"
                  order="2"
                  order-md="1"
                >
                  <b-card-text class="mb-0">
                    <span class="font-weight-bold">Generador:</span>
                    <span class="ml-75">{{userData.names}} {{userData.surnames}}</span>
                  </b-card-text>
                </b-col>
  
                <!-- Col: Total -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-6 d-flex justify-content-end"
                  order="1"
                  order-md="2"
                >
                  <div class="invoice-total-wrapper">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Subtotal :
                      </p>
                      <p class="invoice-total-amount">
                        {{paymentDetails.total_price}}
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Descuento :
                      </p>
                      <p class="invoice-total-amount">
                        $ {{paymentDetails.total_discount}}
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Impuestos :
                      </p>
                      <p class="invoice-total-amount">
                        $ {{paymentDetails.total_tax}}
                      </p>
                    </div>
                    <hr class="my-50">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Total :
                      </p>
                      <p class="invoice-total-amount">
                        {{paymentDetails.total_price}}
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
  
            <!-- Spacer -->
            <hr class="invoice-spacing">
  
            <!-- Note -->
            <b-card-body class="pt-0" style="margin-top: -20px;">
              <span class="font-weight-bold">Nota: </span>
              <span>Los Valores Pueden Cambiar Sin Previo Aviso !</span>
            </b-card-body>
          </b-card>
        </b-col>
  
       
      </b-row>
    </section>
  </template>
  
  <script>
  import { ref, onUnmounted, computed } from '@vue/composition-api'
  import store from '@/store'
  import router from '@/router'
  import {
    BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BImg
  } from 'bootstrap-vue'
  import Logo from '@core/layouts/components/Logo.vue'
  import Ripple from 'vue-ripple-directive'
  import invoiceStoreModule from './invoiceStoreModule'
  import InvoiceSidebarSendInvoice from './InvoiceSidebarSendInvoice.vue'
  import InvoiceSidebarAddPayment from './InvoiceSidebarAddPayment.vue'
  import ECommerce from '@/lavarclick/ecommerce/ECommerce.ts';
  
  export default {
    directives: {
      Ripple,
      'b-toggle': VBToggle,
    },
    components: {
      BRow,
      BCol,
      BCard,
      BCardBody,
      BTableLite,
      BCardText,
      BButton,
      BAlert,
      BLink,
      BImg,
      Logo,
      InvoiceSidebarAddPayment,
      InvoiceSidebarSendInvoice,
    },
    props: {
        order: {
            type: Object,
            required: true
        }

    },
    methods: {

    },
    setup(props, {emit}) {

      const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'
      const invoiceData = ref(null)
      const paymentDetails = ref({})
      const eCommerce = new ECommerce();
      let totalPrice = ref(0);
      let totalDiscount = ref(0);
      let totalTax = ref(0);
      let totalServicesItems  = ref(0);

      const calculatePayment = (order) => {
              order.logistic.collect.items.forEach((serviceitem) => {
                totalPrice.value += ((serviceitem.quantity*1)*(serviceitem.price*1))
                totalServicesItems.value += serviceitem.quantity*1; 

              });
            };

      const computedOrder = computed({
        get: () => { 
          calculatePayment(props.order);
          paymentDetails.value = {
            total_price: totalPrice.value,
            total_services: props.order.logistic.collect.items.length,
            total_service_items: totalServicesItems.value,
            total_discount: totalDiscount.value,
            total_tax: totalTax.value,
            total: (totalPrice.value-totalDiscount.value+totalTax.value)
          };

          return props.order; 
        },
      });

      const createOrder = () => {

        emit('createOrder', computedOrder);

      };
  
      // Register module
      if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)
  
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
      })
  
      const printInvoice = () => {
        window.print()
      }

      console.log(store);
  
      store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true);
      const userData = {
        names: 'Camilo',
        surnames: 'Garcia'
      }

      return {
        invoiceData,
        paymentDetails,
        printInvoice,
        eCommerce,
        computedOrder,
        createOrder,
        userData
      }
    },
    mounted() {
  
      this.eCommerce.getCategories();
    }
  }
  </script>
  
  <style lang="scss" scoped>
  @import "~@core/scss/base/pages/app-invoice.scss";
  </style>
  
  <style lang="scss">
    .startTrainingHeaderStyle {
    height: 120px !important;
    font-size: 20px !important;
    text-align: center !important;
    margin-top: 0px !important;
    width: 100% !important;
    font-family: 'MavenPro' !important;
  }
  @media print {
  
    // Global Styles
    body {
      background-color: transparent !important;
    }
    nav.header-navbar {
      display: none;
    }
    .main-menu {
      display: none;
    }
    .header-navbar-shadow {
      display: none !important;
    }
    .content.app-content {
      margin-left: 0;
      padding-top: 2rem !important;
    }
    footer.footer {
      display: none;
    }
    .card {
      background-color: transparent;
      box-shadow: none;
    }
    .customizer-toggle {
      display: none !important;
    }
  
    // Invoice Specific Styles
    .invoice-preview-wrapper {
      .row.invoice-preview {
        .col-md-8 {
          max-width: 100%;
          flex-grow: 1;
        }
  
        .invoice-preview-card {
          .card-body:nth-of-type(2) {
            .row {
                > .col-12 {
                max-width: 50% !important;
              }
  
              .col-12:nth-child(2) {
                display: flex;
                align-items: flex-start;
                justify-content: flex-end;
                margin-top: 0 !important;
              }
            }
          }
        }
      }
  
      // Action Right Col
      .invoice-actions {
        display: none;
      }
    }
  }
  </style>
  