<template>
  <div class="list-view " style="width: 100% !important;">
   <!-- Checkout Options -->
   
    <b-row >
      <b-col xl="3" cols="12">
        <h6 class="section-label mb-75" style="font-size: 1em !important; color: #000000 !important; font-weight: bold !important;">
          Categorias Disponibles
        </h6>
        <b-form-group class="blog-search">
                    <b-input-group class="input-group-merge">
                    <b-form-input
                    id="search-input"
                    placeholder="Buscar categoria o servicio ..."
                    
                    />
                    <b-input-group-append
                    class="cursor-pointer"
                    is-text
                    >
                    <feather-icon
                    icon="SearchIcon"
                    />
                    </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
        <b-card   style="height: 500px !important; border-style: solid; border-color: #000000; border-width: 0.1em; border-radius: 15px; padding-top: 0px !important; overflow-y: auto;">
          <b-card-body>
            <div style="margin-top: -30px !important;">
        
        <b-media
          v-for="(recentpost,index) in computedCategories"
          :key="index"
        >
          <b-media-body style="padding-top: 30px !important;" v-if="recentpost.is_active">
            <div>
                  <b-row style="margin-top: 0px !important;">
                    <b-col xl="4">
                      <b-img 
                    :src="recentpost.img"
                    :alt="recentpost.img.slice(6)"
                    width="100"
                    rounded
                    height="100"
                      @click="describeCategory(index)"
                  />
                      
                    </b-col>
                    <b-col xl="8">
                      <h3><b>{{recentpost.name}}</b></h3>
                      {{ recentpost.title }}<br/>                     
                      Creado {{ formatDate(new Date(recentpost.created_at*1)) }}
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <table class="serviceTableStyle">
          <tr>
            <th>COD</th><th>SERVICIO</th><th>ESTADO</th>
          </tr>
          <tr v-for="(service, indexSer) in recentpost.services" v-bind:key="indexSer">
            <td>{{service.code}}</td><td>{{service.name}}</td><td>{{ service.is_active ? 'Activo' : 'Inactivo'}}</td>
          </tr>

        </table>
                    </b-col>
                  </b-row>
              </div>
          </b-media-body>
        </b-media>
      </div>
          </b-card-body>
        </b-card>

      </b-col>
      <b-col  xl="5"  cols="12" v-if="flagServiceItems">
        <e-commerce-checkout-step-cart-products :services="computedCategories[indexCategorySelected].services" @addServiceItemToOrder="addServiceItemToOrder" />
      </b-col>
      <b-col  xl="4"  cols="12">
    <!-- Checkout Options -->

    <h6 class="section-label mb-75" style="font-size: 1em !important; color: #000000 !important; font-weight: bold !important;">
      <feather-icon
            icon="ShoppingCartIcon"
            size="70"
            />    
      Carrito de Compras     

        </h6>
    <div class="checkout-options" style="height: 500px !important; border-style: solid; border-color: #000000; border-width: 0.1em; border-radius: 15px; padding-top: 0px !important; overflow-y: auto;">
      
      <b-card >
        <table class="serviceTableStyle">
          <tr>
            <th style="width: 25%">COD</th><th style="width: 25%">SERVICIO</th><th>CANT</th><th style="width: 15%">Vr UN</th><th style="width: 20%">TOTAL</th><th style="width: 15%"></th>
          </tr>
          <tr v-for="(serviceItem, indSI) in computedCart" v-bind:key="indSI">
            <td>{{serviceItem.code}}</td><td>{{serviceItem.name}}</td><td>{{serviceItem.quantity}}</td><td>{{serviceItem.price}}</td><td>{{serviceItem.quantity*serviceItem.price}}</td><td><b-img
                  fluid
                  :src="require('@/assets/images/logo/del.png')"
                  class="pricing-trial-img"
                  alt="svg img" style="width: 40%" @click="removeCartItem(indSI)"
                /></td>
          </tr>

        </table>

        

        <div class="price-details">
          <ul class="list-unstyled">
            <li class="price-detail">
              <div class="detail-title detail-total">
                Total
              </div>
              <div class="detail-amt font-weight-bolder">
                $574
              </div>
            </li>
          </ul>
          
          <label class="section-label mb-1">APLICA UN CUPON</label>
        <b-input-group class="input-group-merge coupons">
          <b-form-input placeholder="Coupons" />
        </b-input-group>
        <hr>
        <b-button
            variant="primary"
            block
            @click="$emit('next-step', businessServiceOrder)"
          >
            Confirmar Servicios
          </b-button>
        </div>

      </b-card>
    </div>
      </b-col>
    </b-row>
    <!-- Products List -->

  </div>
</template>

<script>
import {
  BMedia, BFormGroup, BCardBody, BMediaBody, BButton, BCard, BInputGroup, BFormInput, BInputGroupAppend, BRow, BCol, BCardHeader, BImg
} from 'bootstrap-vue'
import ECommerceCheckoutStepCartProducts from './ECommerceCheckoutStepCartProducts.vue'
import ECommerce from '@/lavarclick/ecommerce/ECommerce.ts';
import BusinessServiceOrder from '@/lavarclick/types/BusinessServiceOrder.ts';
export default {

  props: {
    
  },
  components: {
    // BSV
    BButton,
    BCard,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BRow, BCol, BCardHeader,BMedia, BMediaBody, BImg, BFormGroup, BCardBody,

    // SFC
    ECommerceCheckoutStepCartProducts
  },
  computed: {

    computedCategories: {
      get: function() { return this.categoriesList},
      set: function(categoriesList) {
        this.categoriesList = categoriesList;
      }
    },
    computedCart: {
      get: function() { return this.cartList},
      set: function(cartList) {
        this.cartList = cartList;
      }
    }

  },
  data() {
    return {
      ecommerce: new ECommerce(),
      categoriesList: [],
      flagServiceItems: false,
      businessServiceOrder: new BusinessServiceOrder(),
      cartList: [],
      indexCategorySelected: 0
    }
  },
  methods: {

    removeCartItem(itemIndex) {

      this.computedCart.splice(itemIndex, 1);


    },

    addServiceItemToOrder(serviceItem) {

      this.businessServiceOrder.logistic.collect.items.push(serviceItem);
      console.log('espec ', this.businessServiceOrder);

      this.computedCart = this.businessServiceOrder.logistic.collect.items;

      // this.businessServiceOrder.


    },

    formatDate(date) {

      return date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate();

    },

    setCategory(eventx) {

      console.log(this.computedCategories);
      


    },
    describeCategory(index) {

      this.indexCategorySelected = index;
      this.flagServiceItems = true;
      // console.log(this.computedCategories);
    },

  },
  mounted() {
    this.ecommerce.getCategories({
            get_all: true,
            get_active: false,
            has_services: true,
            has_services_items: true,
            id_user: 0
    }).then((categoriesList) => {
      this.computedCategories = categoriesList;
    });
  }
}
</script>
<style scoped>
.serviceTableStyle {
    margin-left: 0px !important;
    font-family: 'MavenPro' !important;
    border-radius: 10px !important; 
    width: 100% !important;
    margin-top: 0px !important;
    overflow-y: auto !important;
    height: 100% !important;
}
</style>