<template>

  <div>
    <b-overlay :show="loading">
    <div class="pricing-free-trial startTrainingHeaderStyle" >
      <b-row>
        <b-col
          xl="12"
        >
          <div class="pricing-trial-content d-flex justify-content-between">
            <div class="text-center text-md-left mt-2" style="margin-left: 10px !important;">
              <b-row>
                <b-col  xl="12" cols="12">
                  <b-row >
                    <b-col xl="2" class="mt-2">
                      <b-img
              fluid
              :src="require('@/assets/images/logo/logo2.png')"
              class="pricing-trial-img"
              alt="svg img" style="height: 120px; width: 120px" 
            />
                    </b-col>
                    <b-col xl="7" class="mt-0">
                      <div class="text-primary" style="margin-top: 0px !important;">
                FORMULARIO NUEVO SERVICIO <b>LAVARCLICK !</b> <span class="text-secondary">Crea Nuevos Servicios en la Plataforma !</span> 
                      </div>
              <!-- <b-form-group class="blog-search">

<b-input-group class="input-group-merge">
<b-form-input
id="search-input"
v-model="search_query"
placeholder="Buscar categoria o servicio ..."
/>
<b-input-group-append
class="cursor-pointer"
is-text
>
<feather-icon
icon="SearchIcon"
/>
</b-input-group-append>
</b-input-group>
</b-form-group> -->
                    </b-col>
                    <!-- <b-col xl="3" style="padding-top: 0px !important; font-size: 0.8em !important;">
                    ó Crea Una <b-img 
              fluid
              :src="require('@/assets/images/slider/addcat.png')"
              class="pricing-trial-img"
              alt="svg img" style="height: 60px; margin-top: 70px !important;"
              v-b-modal.modal-primary-form
            />
                    
                  </b-col> -->
                  </b-row>
                </b-col>
              </b-row>
              
              
              <!-- <b-form-group class="blog-search">
        <b-input-group class="input-group-merge">
          <b-form-input
            id="search-input"
            v-model="search_query"
            placeholder="Buscar categoria o servicio ..."
          />
          <b-input-group-append
            class="cursor-pointer"
            is-text
          >
            <feather-icon
              icon="SearchIcon"
            />
          </b-input-group-append>
        </b-input-group>
      </b-form-group> -->
            </div>

            <!-- images -->
            <div style="height: 10% !important; width: 30% !important; margin-top: 10px !important; margin-right: -70px !important;">
              
            <b-img
              fluid
              :src="require('@/assets/images/illustration/team2.png')"
              class="pricing-trial-img"
              alt="svg img" style="height: 200px"
            />
            </div>
            
            <!--/ images -->
          </div>
        </b-col>
      </b-row>
    </div>
    <form-wizard 
    ref="refFormWizard"
    color="#7367F0"
    :title="null"
    :subtitle="null"
    finish-button-text="Submit"
    back-button-text="Previous"
    hide-buttons
    @on-change="setMap"
    
    class="checkout-form-wizard steps-transparent" style="width: 100% !important; margin-top: -80px !important;" 
  >
    <!-- account detail tab -->
    <tab-content
      title="Elige Tus Servicios"
      icon="feather icon-shopping-cart" 
    >

      <e-commerce-checkout-step-cart @next-step="formWizardNextStep" />
    </tab-content>

    <!-- address -->
    <tab-content  
      title="Ubicacion"
      icon="feather icon-home" 
    >

    <div class="row" style="padding-left: 15px !important;">

      <div style="width: 20%;">

      <div class="customer-card">
        <b-card title="Selecciona Un Cliente">
          <div style="padding-bottom: 15px !important;">
            <v-select 

            :options="computedCustomerList"
            label="full_name"
            @input="selectCustomerToOrder"
            />
          </div>
          <div style="height: 150px !important; border-style: solid; border-color: #000000; border-width: 0.1em; border-radius: 15px; padding-bottom: 15px;" >
          <b-card-text class="mb-0">
            {{this.computedOrder.customer.full_name}}
          </b-card-text>
          <b-card-text>{{this.computedOrder.customer.gov_id}}</b-card-text>
          <b-card-text>UTC-5: Eastern Standard Time (EST)</b-card-text>
          <b-card-text>202-555-0140</b-card-text>
          </div>

          <b-button
            variant="primary"
            block
            @click="goOrderPay"
          >
            Confirmar Cliente
          </b-button>
          
        </b-card>
      </div>
      <map-basic-position :height="'150px'" :width="'100%'" :locate_service_provider="computedOrderProviders" v-if="flagLocatedProviders" />

          <!-- <e-commerce-checkout-step-address
        :address-details="checkoutDetails.address"
        @next-step="formWizardNextStep" :order="computedOrder"
      /> -->
    </div>
    <div style="width: 40%; padding-left: 15px !important;" v-if="flagMap" >
      <div class="text-primary" style="margin-top: 0px !important; width: 50%;">
                <b>LOGISTICA !</b>
                      </div>
                      <MapComponent :height="'500px'" :width="'100%'" :locate_service_provider="computedOrderProviders" v-if="flagLocatedProviders" @describeCalculatedProviderRoutes="describeCalculatedProviderRoutes"  />

    <div v-if="flagLocatorInstructions" style="height: 250px !important; overflow-y: auto !important;">
      <table class="serviceTableStyle">
        <tr>
            <th>#</th><th>INDICACION</th><th>DIRECCION</th><th>DISTANCIA</th><th>RUMBO</th>
          </tr>
          <tbody>
        <tr v-for="(instruction, indexInstr) in JSON.parse(computedOrder.logistic.dispatch.routes.fromCustomer+''.replace('\\', '')).instructions" v-bind:key="indexInstr">
          <td>
            {{indexInstr+1}}
          </td>
          <td>
            {{instruction.text.replace('right', 'Derecha').replace('left', 'Izquierda').replace('Continue', 'Continue').replace('straight', 'Derecho').replace('to stay', 'Hasta').replace('on ', '').replace('Take', 'Tome').replace('ramp', 'salida').replace('Towards', 'Hacia').replace('Go', 'Siga').replace('onto', 'Hacia').replace('the', 'la')}}
          </td>
          <td>
            {{instruction.road}}
          </td>
          <td>
            {{instruction.distance}}
          </td>
          <td>
            {{instruction.direction}}
          </td>
        </tr>
        <tr>
          <td colspan="10">

          </td>
        </tr>
      </tbody>
      </table>
    </div>
  </div>
    <div class="row" style="width: 40%; height: 300px; padding-left: 15px !important;" >
      <div>
      <!-- <b-img
              fluid
              :src="require('@/assets/images/icons/locator.png')"
              class="pricing-trial-img"
              alt="svg img" style="height: 150px" 
            /> -->
            </div>
            <div class="text-primary" style="margin-top: 0px !important; width: 100%;" @click="locateServiceProviders">
                FORMULARIO NUEVO SERVICIO <b>LAVARCLICK !</b>

                <table class="serviceTableStyle">
          <tr>
            <th style="width: 50% !important;">COD</th><th>SERVICIO</th><th>CANTIDAD</th><th>ESTADO</th>
          </tr>
          <tr v-for="(serviceItem, indSI) in computedOrder.logistic.collect.items" v-bind:key="indSI">
            <td>{{serviceItem.code}}</td><td>{{serviceItem.name}}</td><td>{{serviceItem.quantity}}</td><td>        <table class="serviceTableStyle">
          <tr>
            <th>COD</th><th>AFILIADO</th><th>LAT</th><th>LNG</th>
          </tr>
          <tr v-if="flagLocator && (indSI < computedOrder.partners.length)" >
            <td></td><td>{{computedOrder.partners[indSI].name}}</td><td>{{computedOrder.partners[indSI].lat}}</td><td>{{computedOrder.partners[indSI].lng}}</td>
          </tr>
        </table>
      </td>
          </tr>
        </table>



                      </div>
                      <div style="margin-top: 0px !important; width: 100%;" >
                        <table v-if="flagLocatedProvidersRoutes" class="serviceTableStyle">
          <tr>
            <th>COD</th><th>AFILIADO</th><th>LAT</th><th>LNG</th>
          </tr>
          <tr v-for="(located, indexL) in computedOrder.partners" v-bind:key="indexL">
            <td @click="describeRoute">{{located.name}} {{located.city_name}}</td><td>{{JSON.parse(computedOrder.logistic.dispatch.routes.fromCustomer+''.replace('\\', '')).summary.totalDistance}}</td><td>{{JSON.parse(computedOrder.logistic.dispatch.routes.fromProvider+''.replace('\\', '')).summary.totalDistance}}</td><td>{{}}</td>
          </tr>
        </table>

                      </div>
    </div>

</div>

    </tab-content>

    <!-- social link -->
    <tab-content 
      title="Pago"
      icon="feather icon-credit-card"
    >
    <div v-if="flagPayment">
      <e-commerce-checkout-step-payment @createOrder="createOrder"
        :payment-details="checkoutDetails.payment" :order="computedOrder"
        @next-step="formWizardNextStep"
      />
    </div>
    </tab-content>
  </form-wizard>

</b-overlay> 
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ref, computed } from '@vue/composition-api'
import ECommerceCheckoutStepPayment from './ECommerceCheckoutStepPayment.vue'
import ECommerceCheckoutStepCart from './ECommerceCheckoutStepCart.vue'
import ECommerceCheckoutStepAddress from './ECommerceCheckoutStepAddress.vue'
import MapComponent from '@/lavarclick/components/maps/MapComponent.vue';
import MapBasicPosition from '@/lavarclick/components/maps/MapBasicPosition.vue';
import vSelect from 'vue-select';
import ToastContent from '@/lavarclick/components/utils/ToastContent.vue';

import {
  BTab, BButton, BTabs, BCard, BCardText, BAlert, BLink, BImg, BRow, BCol, BModal, VBModal, BCardBody, BOverlay
} from 'bootstrap-vue';
import BusinessServiceOrder from '@/lavarclick/types/BusinessServiceOrder.ts';
import ECommerce from '@/lavarclick/ecommerce/ECommerce.ts';
import Customer from '@/lavarclick/ecommerce/Customer.ts';

export default {
  components: {
    // 3rd Party
    FormWizard, MapBasicPosition,
    TabContent,
    BTab, BTabs, BCard, BAlert, BLink, BImg, BRow, BCol, BModal, VBModal, BCardBody,BOverlay,
    // SFC
    ECommerceCheckoutStepCart,
    ECommerceCheckoutStepAddress,
    ECommerceCheckoutStepPayment,
    MapComponent, BCardText, vSelect, BButton, ToastContent
  },
  setup(props, {emit}) {
    const refFormWizard = ref(null);
    let flagMap = ref(null);
    let order = ref({});
    let customerList = ref([]);
    let flagLocatedProviders = false;
    let flagLocatedProvidersRoutes = false;
    let flagPayment = ref(null);
    let loading = ref(true);
    const computedCustomerList = computed({
      get() {
        return customerList.value;
      },
      set(list) {
        customerList.value = list;
      }
    });

    const computedOrder = computed({
      get() {
        return order.value;
      },
      set(orderModified) {
        order.value = orderModified;
      }
    });

    let orderProviders = ref({});

    const computedOrderProviders = computed({
      get: () => { return orderProviders.value; },
      set: (locatedServiceProviders) => {

        orderProviders.value = locatedServiceProviders;
        console.log('americ ', locatedServiceProviders);
      }
    })

    // const locateServiceProviders = () => {
    //   console.log('alcaldia', computedOrder);
    // };

    const goOrderPay = () => {
      refFormWizard.value.nextTab();
      console.log('filo ', computedOrder);
      flagPayment.value = true;
      // emit('next-step', computedOrder);
    }
    const formWizardNextStep = (order) => {
      refFormWizard.value.nextTab();
      computedOrder.value = order;
    }

    const setMap = (tab, tab2) => {

      console.log(tab, tab2);
      if(tab2 === 1) {

flagMap.value = true;

}else {

flagMap.value = false;

}
      return true;

    };

    // ? This is just dummy details
    const checkoutDetails = ref({
      address: {
        fullName: '',
        mobile: '',
        houseNo: '',
        landmark: '',
        city: '',
        pincode: '',
        state: '',
        addressType: null,
      },
      payment: {
        cvv: '',
      },
    })

    const ecommerce = new ECommerce();
    let flagLocator = false;
    let flagLocatorInstructions = false;
    return {
      loading,
      refFormWizard,
      formWizardNextStep,
      flagMap,
      setMap,
      computedOrder,
      ecommerce,
      flagLocator,
      computedCustomerList,
      computedOrderProviders,
      flagLocatedProviders,
      flagLocatedProvidersRoutes,
      flagLocatorInstructions,
      goOrderPay,
      flagPayment,
      // Dummy Details
      checkoutDetails
    }
  },
  methods: {

    createOrder(newOrder) {

      console.log('newnew', newOrder);

      this.ecommerce.createOrder(newOrder.value).then((orderCreated) => {
        this.computedOrder.id_order = orderCreated.createdOrder.id_order;
        this.computedOrder.timeline.created_at = orderCreated.createdOrder.timeline.created_at;
        this.$toast({
                        component: ToastContent,
                        position: 'b-toaster-top-center',
                        props: {
                            title: `Se Ha Creado Con Exito La Nueva Cotizacion `,
                            icon: 'CoffeeIcon',
                            variant: 'default',
                            text: `Muy Bien !`,
                            bgcolor: '#55a64b'
                            
                        },
                        delay: 15000
                        });
        console.log('muy bien lo logre', orderCreated.createdOrder);

      });
    },

    selectCustomerToOrder(customerSelected) {

      this.computedOrder.customer = customerSelected;
      console.log('levi ', this.computedOrder);

    },

    describeRoute(eventx) {

      this.flagLocatorInstructions = true;

    },

    locateServiceProviders(eventx) {

      delete this.computedOrder.logistic.collect.partners;
      // this.businessServiceOrder.logistic.collect.items.push(service);

      this.ecommerce.locateServicesProviders(this.computedOrder).then((locatedProviders) => {

        this.computedOrder.logistic.collect = locatedProviders[0].logistic_collect;
        this.computedOrder.partners = locatedProviders[0].logistic_collect.partners;

        this.computedOrderProviders = {
          customer: this.computedOrder.customer,
          locatedPartners: this.computedOrder.partners
        };

        console.log('helio ', this.computedOrder);
        this.flagLocatedProviders = true;
        this.flagLocator = true;

      });
    },
    describeCalculatedProviderRoutes(calculatedProviderRoute) {

      this.computedOrder.logistic.dispatch.routes.fromCustomer = JSON.stringify(calculatedProviderRoute[0]).replace('\\', '');
      this.computedOrder.logistic.dispatch.routes.fromProvider = JSON.stringify(calculatedProviderRoute[1]).replace('\\', '');
      this.flagLocatedProvidersRoutes = true;

      console.log('merca ', this.computedOrder, JSON.parse(this.computedOrder.logistic.dispatch.routes.fromCustomer+''.replace('\\', '')));

    }

  },
  created() {

    console.log('cami');
    this.computedOrder = new BusinessServiceOrder();
    this.computedOrder.customer = new Customer();
    this.ecommerce.getECommerceCustomer({
            get_all: true,
            get_active: true,
            get_customers_by_city: 0,
            id_employee: 0
        }).then((ecommerceList) => {
          this.computedCustomerList = ecommerceList;
          this.loading = false;
        });

    
    
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';
</style>

<style lang="scss" scoped>
.checkout-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}
.startTrainingHeaderStyle {
  height: 120px !important;
  font-size: 20px !important;
  text-align: center !important;
  margin-top: 0px !important;
  width: 100% !important;
  font-family: 'MavenPro' !important;
}

.serviceTableStyle {
    margin-left: 0px !important;
    font-family: 'MavenPro' !important;
    border-radius: 10px !important; 
    width: 100% !important;
    margin-top: 0px !important;
    overflow-y: auto !important;
}
</style>
