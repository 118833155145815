//@ts-ignore
import Location from '@/lavarclick/ecommerce/Location.ts';

export default class Customer {
    constructor() {
        return {
            avatar: '',
            company: '',
            created_at: '',
            email: '',
            email_verified_at: '',
            fk_user_customization: 0,
            id_scanclick_users: 0,
            names: '',
            password: '',
            remember_token: '',
            surnames: '',
            updated_at: '',
            gov_id: '',
            type: '',
            full_name: '',
            location: new Location(),
            id_customer: 0
        }
    }
}