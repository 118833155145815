export default class Ticket {

    constructor() {
        return {

            id_ticket: 0,
            id_sender: 0,
            sender_name: '',
            id_resolver: 0,
            resolver_name: '',
            ticket_type: {

                id_ticket_type: 0,
                type: '',
                rating_bottom: 0,
                rating_top: 0

            },
            created_at: '',
            resolved_at: '',
            closed_at: '',
            title: '',
            ticket: '',
            response: '',
            customer_rating: 0,
            customer_observation: '',
            related_orders: ''
        }
    }
}