<template>
  <div class="list-view product-checkout">

    <b-row>
      <b-col xl="5">
        <InvoiceComponent :order="order" @createOrder="createOrder" />
      </b-col>
      <b-col>
    <!-- Left Card -->
    <b-card no-body>
      <b-card-header class="flex-column align-items-start">
        <b-card-title>Pago Rapido</b-card-title>
        <b-card-text class="text-muted mt-25">
          Be sure to click on correct payment option
        </b-card-text>
      </b-card-header>
      <b-card-body>
        <h6 class="card-holder-name my-75">
          
        </h6>
        <b-row>
          <b-col>        <b-img
          src="@/assets/images/logo/pse.png"
          />
</b-col>
<b-col>
  <b-form-group>
          <b-form-radio
            name="payment-method"
            value="john-doe-debit-card"
            checked="john-doe-debit-card"
          >
            Realizar Pago x PSE
          </b-form-radio>
          <div class="d-flex flex-wrap align-items-center justify-content-start my-1">
            <label
              for="cvv"
              class="text-nowrap mr-1 mb-1"
            >
              Seleccione El Banco
            </label>
            <b-form-input
              id="cvv"
              v-model="paymentDetails.cvv"
              class="mr-1 mb-1"
              trim
            />
            <b-button
              variant="primary"
              class="mb-1"
            >
              Iniciar Pago
            </b-button>
          </div>
          </b-form-group>
</b-col>
        </b-row>

        <!-- Radios -->

        <hr class="my-2">

        <p class="cursor-pointer">
          <feather-icon
            icon="PlusCircleIcon"
            size="21"
            class="mr-75"
          />
          <span class="align-middle">Add Gift Card</span>
        </p>
      </b-card-body>
    </b-card>

      </b-col>
      <b-col>
    <!-- Right Card -->
    <div class="amount-payable checkout-options">
      <b-card title="Otras Opciones de Pago">

        <b-form-group>
          <hr class="mb-2 mt-1">
          

          <b-form-radio
            name="payment-method"
            value="debit-atm-credit-card"
          >
          
            Pago Contra Entrega
          </b-form-radio>
          <b-form-radio
            name="payment-method"
            class="mt-1"
            value="net-banking"
          >
            Pago En Efectivo
          </b-form-radio>
          <b-form-radio
            name="payment-method"
            class="mt-1"
            value="emi"
          >
            EMI (Easy Installment)
          </b-form-radio>
          <b-form-radio
            name="payment-method"
            class="mt-1"
            value="cod"
          >
            Cash On Delivery
          </b-form-radio>
        </b-form-group>


      </b-card>
    </div>

      </b-col>
    </b-row>


  </div>
</template>

<script>
import {
  BCard, BCardHeader, BRow, BCol, BImg, BCardTitle, BCardText, BCardBody, BFormGroup, BFormRadio, BFormInput, BButton,
} from 'bootstrap-vue';
import InvoiceComponent from '@/lavarclick/components/billing/invoice/InvoiceComponent.vue';

export default {
  components: {
    // BSV
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BFormGroup,
    BFormRadio,
    BFormInput,
    BButton,
    BImg, BRow, BCol, InvoiceComponent
  },
  props: {
    paymentDetails: {
      type: Object,
      required: true,
    },
    order: {
            type: Object,
            required: true
        }

  },
  methods: {

    createOrder(newOrder) {

      this.$emit('createOrder', newOrder);

    }
  }
}
</script>

<style lang="scss" scoped>
#cvv {
  width: auto;
}
</style>
