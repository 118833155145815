<template>

  <div class="checkout-items" >
    <h6 class="section-label mb-75" style="font-size: 1em !important; color: #000000 !important; font-weight: bold !important;">
          Servicios Disponibles
        </h6>
    <b-form-group class="blog-search">
                    <b-input-group class="input-group-merge">
                    <b-form-input
                    id="search-input"
                    placeholder="Buscar servicios ..."
                    
                    />
                    <b-input-group-append
                    class="cursor-pointer"
                    is-text
                    >
                    <feather-icon
                    icon="SearchIcon"
                    />
                    </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                  <div v-if="services" style="height: 500px !important; border-style: solid; border-color: #000000; border-width: 0.1em; border-radius: 15px; padding-top: 0px !important; overflow-y: auto;">
                    
                    <!-- {{services}} -->
<div v-for="(service, indS) in services" v-bind:key="indS">

  <h4 style="margin-left: 15px !important; margin-top: 15px !important;">
    {{service.name}}
  </h4>
    <b-card
      v-for="product in products.slice(0,1)"
      :key="product.id"
      class="ecommerce-card"
      no-body
    >
    <!-- {{product}} -->

      <!-- Product Image -->
      

      <!-- Product Details: Card Body -->
      <b-card-body>
        <div class="row" v-for="(serviceItem, indexSerItem) in service.catalog" v-bind:key="indexSerItem">
          
        <div class="item-img">
        <b-link>
          <b-img
            :src="serviceItem.image"
            :alt="`${product.name}-${product.id}`" style="height: 100px;"
          /> 
        </b-link>
        <div class="item-wrapper">
          <div class="item-cost">
            <h4 class="item-price">
              $ {{ serviceItem.price }}
            </h4>
            <p
              v-if="product.hasFreeShipping"
              class="card-text shipping"
            >
              <b-badge
                pill
                variant="light-success"
              >
                COP
              </b-badge>
            </p>
          </div>
        </div>
      </div>
        <div class="item-name" style="padding-top: 15px !important;">
          <h4 class="mb-0">
              {{ serviceItem.name }}
          </h4>
          <!-- <span class="item-company">By <b-link class="company-name">{{ product.brand }}</b-link></span> -->
          <div class="item-rating">
            <ul class="unstyled-list list-inline">
              <li
                v-for="star in 5"
                :key="star"
                class="ratings-list-item"
                :class="{'ml-5': star-1}"
              >
                <feather-icon
                  icon="StarIcon"
                  size="16"
                  :class="[{'fill-current': star <= product.rating}, star <= product.rating ? 'text-warning' : 'text-muted']"
                />
              </li>
            </ul>
          </div>
          <span class="text-success mb-1">Disponible</span>
        <div class="item-quantity">
          <span class="quantity-title">Cantidad: </span>
          <b-form-spinbutton
            v-model="serviceItem.quantity"
            size="sm"
            class="ml-75"
            inline
          />
          <b-img
            src="@/assets/images/icons/addcart.png"
            @click="addServiceItemToOrder(serviceItem)"
          /> 

        </div>
        <span class="delivery-date text-muted">Posible Entrega {{ formatDate(product.shippingDate, { month: 'short', day: 'numeric', weekday: 'short' }) }}</span>
        <span class="text-success">{{ product.discountPercentage }}% off {{ product.offers }} offers Available</span>
        </div>
        </div>

      </b-card-body>

      <!-- Product Options/Actions -->
      <!-- <div class="item-options text-center">
      
        <b-button
          variant="light"
          class="mt-1 remove-wishlist"
          @click="removeProductFromCartClick(product)"
        >
          <feather-icon
            icon="XIcon"
            class="mr-50"
          />
          <span>Remove</span>
        </b-button>
        <b-button
          variant="primary"
          class="btn-cart move-cart"
          @click="toggleProductInWishlist(product)"
        >
          <feather-icon
            icon="HeartIcon"
            class="mr-50"
            :class="{'fill-current': product.isInWishlist}"
          />
          <span class="text-nowrap">Wishlist</span>
        </b-button>
      </div> -->
    </b-card>
  </div>
  </div>
  </div>
</template>

<script>
import {
  BCard, BCardBody, BLink, BImg, BButton, BBadge, BFormSpinbutton, BFormGroup, BFormInput, BInputGroup , BInputGroupAppend
} from 'bootstrap-vue'
import store from '@/store'
import { ref } from '@vue/composition-api'
import { formatDate } from '@core/utils/filter'
import { useEcommerce, useEcommerceUi } from './useEcommerce'

export default {
  components: {
    BCard, BCardBody, BLink, BImg, BButton, BBadge, BFormSpinbutton,BFormGroup, BFormInput, BInputGroup, BInputGroupAppend
  },
  props: {
    services:Array
  },
  setup(props, {emit}) {
    const products = ref([])

    const addServiceItemToOrder = (service) => {
      emit('addServiceItemToOrder', service);
    };

    const { toggleProductInWishlist } = useEcommerceUi()
    const { removeProductFromCart } = useEcommerce()
    const removeProductFromCartClick = product => {
      removeProductFromCart(product.id)
        .then(() => {
          const productIndex = products.value.findIndex(p => p.id === product.id)
          products.value.splice(productIndex, 1)

          store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', products.value.length)
        })
    }

    const fetchCartProducts = () => {
      store.dispatch('app-ecommerce/fetchCartProducts')
        .then(response => {
          products.value = response.data.products
        })
    }
    fetchCartProducts()

    return {
      products,

      // UI
      toggleProductInWishlist,
      removeProductFromCartClick,
      addServiceItemToOrder,
      // Filter
      formatDate,
    }
  },
  methods: {
    
  },
  mounted() {
    console.log('pronto', this.services);
  }
}
</script>

<style>

</style>
